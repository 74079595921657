.headerContainer {
    padding: 10px 50px;
    position: fixed;
    width: 100vw;
    top: 0px;
}

.avatar24 {
    width: 24px !important;
    height: 24px !important;
}

.w15 {
    width: 15px !important;
}

.h15 {
    height: 15px !important;
}

.p4 {
    padding: 4px;
}

.logoIco {
    width: 6vw !important;
    height: 3vw !important;
    margin-right: 10px;
    position: relative;
    top: 0px;
    max-width: 65px;
    max-height: 30px;
}

.logoIcoInvert {
    filter: invert(1);
}

.headerFontClamp{
    font-size: var(--font-m) !important;
}

.headerIcoClamp1525{
    width: clamp(15px, 2vw, 25px) !important;
    height: clamp(15px, 2vw, 25px) !important;
}

.headerIcoClamp2535{
    width: clamp(25px, 2vw, 35px) !important;
    height: clamp(25px, 2vw, 35px) !important;
}

.headerInner{
    border-radius: var(--app-border-radius-s);
    padding: 12px;
    box-shadow: var(--shadow-down-s-transparent);
}

.mw11vw{
    max-width: 11vw;
}