/* Thin (100) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

/* ExtraLight (200) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/* Light (300) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/* Regular (400) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Medium (500) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* SemiBold (600) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

/* Bold (700) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/* Thin Italic (100) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

/* ExtraLight Italic (200) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

/* Light Italic (300) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* Italic (400) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* SemiBold Italic (600) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

/* Bold Italic (700) */
@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM_Plex_Mono/IBMPlexMono-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
