.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 500px;
}

.loader {
  width: 12px;
  height: 20px;
  border-radius: 4px;
  display: block;
  color: #FFF;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
  }

  33% {
    box-shadow: -38px 0px, -14px -12px, 14px 0, 38px 0;
  }

  66% {
    box-shadow: -38px 0px, -14px 0, 14px -12px, 38px 0;
  }

  100% {
    box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
  }
}