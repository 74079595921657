@import './app-font-IBM-Plex_mono.scss';
@import './app-font-Roboto.scss';

:root {
  --app-background: #000000;
  --app-accent-color: #03A062;
  --app-accent-color-lighter: #77ffc8;
  --app-accent--darker: #006b40;
  --app-accent2-color: #0095F6;
  --app-accent2-color-lighter: #67bdff;
  --app-accent2-color-darker: #247cbf;
  --white-color: #ffffff;
  --string-color: #CE9178;
  --app-error-color: #ff0000;
  --app-error-color-lighter: #ff7272;
  --app-error-color-darker: #950000;
  --app-info-color: #ecec00;
  --app-info-color-lighter: #ffff9e;
  --app-info-color-darker: #9c9c00;
  --app-success-color: #03A062;
  --app-success-color-lighter: #77ffc8;
  --app-success-darker: #006b40;
  --app-light-gray-color: #d6d6d6;
  // --app-font-family: 'IBM Plex Mono', Courier, 'Courier New', monospace;
  --app-font-family: 'Roboto', Courier, 'Courier New', monospace;
  --font-xxs: 0.2rem;
  --font-xs: 0.4rem;
  --font-s: 0.6rem;
  --font-sm: 0.8rem;
  --font-m: 1rem;
  --font-ml: 1.2rem;
  --font-l: 1.4rem;
  --font-xl: 1.6rem;
  --font-xxl: 1.8rem;
  --app-transition: 0.2s ease-in-out;
  --shadow-ini-down-s: rgb(0 0 0 / 5%) 0px 5px 100px -20px, rgb(0 0 0 / 9%) 0px 5px 60px -30px;
  --shadow-hover-down-s: rgb(0 0 0 / 16%) 0px 50px 100px -20px, rgb(0 0 0 / 17%) 0px 30px 60px -30px;
  --shadow-down-s: rgba(100, 100, 111, 0.2) 2px 2px 15px 3px;
  --shadow-down-s-transparent: rgb(0 0 0 / 12%) 2px 2px 15px 3px;
  --shadow-right-normal: rgb(0 0 0 / 38%) 5px 0px 30px;
  font-size: clamp(15px, 2vw, 20px);
  --app-border-radius-m: 6px;
  --app-border-radius-s: 6px;
  --app-border-radius-xs: 6px;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: var(--app-font-family);
  /* color: var(--app-accent-color); */
  background-color: var(--app-background);
  // background-image: url("./assets/images/light_background.jpg");
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
  /* overflow: hidden; */
}

// body::before {
//   content: "";
//   position: fixed; /* Ensure it stays in place while scrolling */
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url('./assets/images/arches-1837166_1920.jpg');
//   background-attachment: fixed;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center center;
//   filter: blur(8px); /* Adjust the blur amount as needed */
//   z-index: -1; /* Ensure it is behind other content */
// }

body * {
  font-family: var(--app-font-family) !important;
  font-size: var(--font-m) !important;
  font-weight: 300;
  letter-spacing: 1px;
  /* color: var(--app-accent-color) !important; */
}

.hidden{
  opacity: 0 !important;
  transform: scale(0.7);
  *{
    opacity: 0 !important;
  }
  transition: ease-in-out 1s;
}

.fw300 {
  font-weight: 300 !important;
}

.df {
  display: flex;
}

.jc {
  justify-content: center;
}

.js {
  justify-content: start;
}

.jsb {
  justify-content: space-between;
}

.je {
  justify-content: end;
}

.ac {
  align-items: center;
}

.fw {
  flex-wrap: wrap;
}

.gp50px {
  row-gap: clamp(14px, 2vw, 29px) !important;
  column-gap: clamp(8px, 2vw, 29px) !important;
}

.gp30px {
  gap: clamp(5px, 2vw, 20px) !important;
}

.f100 {
  flex: 0 0 100%;
}

.f50 {
  flex: 0 0 50%;
}

.w90vw {
  width: 90vw;
  min-width: 300px;
}

.mw4096 {
  max-width: 4096px;
}

.w50vw {
  width: 50vw;
  min-width: 300px;
}

.w75vw {
  width: 75vw;
  min-width: 270px;
}

.w80vw {
  width: 80vw;
  min-width: 300px;
}


.w45vw {
  width: 40vw;
  min-width: 300px;
}

.h100vh {
  height: 100vh;
}

.pb5{
  padding-bottom: 5px;
}

.p0m0 {
  padding: 0px;
  margin: 0px;
}

.p0m00150 {
  padding: 0px;
  margin: 0px 0px 15px 0px;
}

.mb3px {
  margin-bottom: 3px !important
}

.pb3px {
  padding-bottom: 3px !important
}

.p40 {
  padding: 40px;
}

.pt3px {
  padding-top: 3px
}

.mb40 {
  margin-bottom: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.m10 {
  margin: 10px;
}

.mt15r15b5l15 {
  margin: 15px 15px 5px 15px;
}

.m15 {
  margin: 15px;
}

.ml15 {
  margin-left: 15px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.m20 {
  margin: 20px;
}

.w100p {
  width: 100px;
}

.w300p {
  width: 300px;
}

.w90per {
  width: 90%
}

.w80per {
  width: 80%;
}

.w50per {
  width: 50%
}

.w180 {
  width: 180px;
}

.w65vw {
  width: 65vw;
}

.w100vw {
  width: 100vw;
}

.full-width {
  width: 100%;
}

.mw0px {
  min-width: 0px !important;
}

.matrix-card-container.full-width {
  .MuiPaper-root {
    width: 90vw;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* mui override starts */
.MuiPaper-elevation {
  //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.MuiInputBase-root {
  border-radius: var(--app-border-radius-s);
  border-top-left-radius: var(--app-border-radius-s) !important;
  border-top-right-radius: var(--app-border-radius-s) !important;
}

.MuiTypography-root {
  // line-height: 1 !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  // color: var(--app-accent-color) !important;
  padding: 5px 5px 5px 0px !important;
  transform: translate(0px, 0px) scale(1);
  position: unset !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 5px;
}

.MuiInputBase-input.MuiFilledInput-input {
  padding: 16px !important;
}

.MuiInputBase-root.MuiFilledInput-root::before {
  border: none !important;
  border-radius: 5px !important;
}

.MuiInputBase-root.MuiFilledInput-root::after {
  border: none !important;
  border-radius: 5px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {}

.MuiInputBase-input {
  /* color: var(--white-color) !important; */
}

.MuiFormHelperText-root {
  color: var(--app-accent-color) !important;
  margin-left: 0px !important;
}

fieldset {
  display: none !important;
}

/* Mui Table css override starts */
.matrix-table {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-container {
    thead {
      tr {
        th {
          color: var(--app-accent-color);
        }
      }
    }

    tr {
      transition: var(--app-transition);
      cursor: pointer;

      th,
      td {
        border: none;
      }

      th:first-child,
      td:first-child {
        text-align: start;
      }

      th:last-child,
      td:last-child {
        text-align: end;
      }
    }

    tbody {
      tr {
        border-left: 2px solid var(--app-accent-color);
      }

      tr:hover {
        background-color: var(--app-accent2-color-darker);
        transition: var(--app-transition);
      }
    }
  }
}

.MuiTablePagination-root {
  border: none !important;
}

.MuiTablePagination-toolbar {
  flex-wrap: wrap;

  p {
    margin: 2px !important;
  }
}

.MuiSvgIcon-root {
  cursor: pointer;
}

.MuiCardActions-root {
  padding-left: 15px !important;
}

.MuiPaper-root {
  padding: 5px;
}

.MuiChip-root {
  margin: 3px 5px 3px 0px;
}

.MuiChip-label {
  max-width: 300px;
}

/* Mui Table css override ends */

/* mui override ends */


.header {
  font-size: var(--font-xl) !important;
  color: var(--app-accent-color);
}

.headerl {
  font-size: var(--font-l) !important;
  color: var(--app-accent-color);
  margin: 10px 0px;
}

.subHeader {
  font-size: var(--font-l);
  color: var(--app-accent-color);
}

.list-border {
  border-left: 2px solid var(--app-accent-color);
  padding: 20px;
}

.list-border {
  border-left: 2px solid var(--app-accent-color);
  padding: 20px;
  margin: 0px;
  margin-top: 25px;
  width: 100%;
  transition: var(--app-transition);
}


.list-border:hover {
  background-color: var(--app-accent2-color-darker);
  border-left: 2px solid var(--app-accent2-color);
  color: var(--white-color);
  cursor: pointer;
  transform: scale(1.03);
  transition: var(--app-transition);
}

.textwrapNone {
  text-wrap: nowrap;
}

.blueText {
  color: var(--app-accent2-color) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 196px;
}

.brownText {
  color: var(--string-color) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 196px;
}

.whiteText {
  /* color: var(--white-color) !important; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 196px;
}

.app-header {
  margin: 0px 20px 2vw 20px;
}

/* react perfect scroll bar css override starts */
.perfect-scroll-bar {
  .content {
    width: 100%;
    height: 100vh;
  }

  .ps--clicking,
  .ps--clicking:hover,
  .ps__rail-y:hover,
  .ps__rail-x:hover {
    background: transparent !important;
  }

  .ps__thumb-x,
  .ps__thumb-y {
    background-color: var(--app-accent-color) !important;
    border-radius: 0px !important;
  }

  .ps--clicking .ps__thumb-x:hover,
  .ps--clicking:hover .ps__thumb-x:hover,
  .ps--clicking .ps__thumb-y:hover,
  .ps--clicking:hover .ps__thumb-y:hover {
    background-color: var(--app-accent2-color) !important;
    cursor: pointer !important;
  }

  .ps--clicking .ps__thumb-x,
  .ps--clicking:hover .ps__thumb-x,
  .ps--clicking .ps__thumb-y,
  .ps--clicking:hover .ps__thumb-y {
    background-color: var(--app-accent2-color) !important;
    cursor: pointer !important;
  }

  .ps__thumb-x:hover,
  .ps__thumb-y:hover {
    background-color: var(--app-accent2-color) !important;
    cursor: pointer !important;
  }
}

.customHeight1 {
  height: calc(100vh - 100px) !important;
}

/* react perfect scroll bar css override ends */


/* browser scroll bar css override starts */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--app-accent-color);
  transition: var(--app-transition);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--app-accent2-color);
  transition: var(--app-transition);
  cursor: pointer;
}

/* browser scroll bar css override starts */

/* span, p, h1, h2, h3, h4, h5, h6, a, li, label, strong, td, th {
  cursor: text;
} */

.errorText {
  color: var(--app-error-color) !important;
}

.mainContainer {
  margin: 10px 10px 100px 10px;
  margin-top: calc(59px + 3vw);
}

.matrix-card-container {
  width: 45%;
  min-width: 296px;
  max-width: 400px;

  .MuiCard-root {
    height: 100%;
    padding-bottom: clamp(45px, 3vw, 58px);
    position: relative;

    .MuiCardActions-root {
      position: absolute;
      bottom: 0px;
      padding-bottom: 12px;
    }
  }
}

.matrix-card-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .matrix-card-container {
    border-radius: var(--app-border-radius-m) !important;
    overflow: hidden;
    box-shadow: var(--shadow-ini-down-s);
    cursor: text;
    transition: var(--app-transition);
  }

  .matrix-card-container:hover {
    transform: scale(1.00);
    box-shadow: var(--shadow-hover-down-s);
    transition: var(--app-transition);
  }
}

.searchBar {
  width: 77vw;
  min-width: 250px !important;
}

.searchBarContainer {
  position: relative;
  left: -16px;

  .searchIconContainer {
    width: 30px !important;
    height: 30px !important;
    position: relative;
    top: 17px;
    left: 46px;
  }

  .MuiInputBase-input.MuiFilledInput-input {
    padding: 16px 60px !important;
  }
}

.icon30 {
  width: 30px !important;
  height: 30px !important
}

.smallMenu * {
  font-size: var(--font-sm) !important;
}

.crPointer {
  cursor: pointer;
}

.iconTp2n {
  position: relative;
  top: -2px;
}

.loader-container {
  .loader {
    color: var(--app-accent-color);
  }
}

.avatar100 {
  width: 10vw !important;
  height: 10vw !important;
  max-width: 125px;
  max-height: 125px;
  min-width: 75px;
  min-height: 75px;
}

.icon40Btn {
  width: clamp(30px, 2vw, 40px) !important;
  min-width: clamp(30px, 2vw, 40px) !important;
  height: clamp(30px, 2vw, 40px) !important;
}

hr.cardContentSeperator {
  margin: 20px 17px 0px 17px;
  border: 1px solid lightgray;
  border-bottom: none;
}

.lightGrayChip {
  background-color: var(--app-light-gray-color) !important;
}

.headerIcoClamp2535 {
  width: clamp(25px, 2vw, 35px) !important;
  height: clamp(25px, 2vw, 35px) !important;
}

.pinIconContainer {
  position: relative;
  float: right;
  transform: rotateZ(45deg);
  z-index: 899;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg.pinned {
    color: var(--app-info-color);
  }
}

.morpheusThemer {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.redPillThemeBtn {
  position: relative;
  bottom: 55px;
  right: 47px;
  width: 47px;
  height: 39px;
  opacity: 0;
  margin-bottom: -35px;
  cursor: pointer;
}

.bluePillThemeBtn {
  position: relative;
  bottom: 55px;
  right: -46px;
  width: 48px;
  height: 39px;
  opacity: 0;
  margin-bottom: -35px;
  cursor: pointer;
}