.themeToggleBtn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnContainer{
  border-radius: 50%;
}

.toggleImg {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.matrixPillIcon{
  height: 16px;
  width: 16px;
  padding: 0px;
}