/* Thin (100) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

/* Thin Italic (100) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

/* Light (300) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

/* Light Italic (300) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

/* Regular (400) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

/* Italic (400) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

/* Medium (500) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

/* Medium Italic (500) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

/* Bold (700) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

/* Bold Italic (700) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

/* Black (900) */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

/* Black Italic (900) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

/* SemiBold Italic (600) */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}
