.avatar24 {
    width: 24px !important;
    height: 24px !important;
}

.w15 {
    width: 15px !important;
}

.h15 {
    height: 15px !important;
}

.p4 {
    padding: 4px;
}

.logoIco {
    width: 6vw !important;
    height: 3vw !important;
    margin-right: 10px;
    position: relative;
    top: 0px;
    max-width: 65px;
    max-height: 30px;
}

.logoIcoInvert {
    filter: invert(1);
}

.sideBarFontClamp{
    font-size: var(--font-m) !important;
}

.sideBarIcoClamp1525{
    width: clamp(15px, 2vw, 25px) !important;
    height: clamp(15px, 2vw, 25px) !important;
}

.sideBarIcoClamp2535{
    width: clamp(25px, 2vw, 35px) !important;
    height: clamp(25px, 2vw, 35px) !important;
}

.sideBarContainer{
    padding: 12px;
    box-shadow: var(--shadow-right-normal);
    width: 5vw;
    min-width: 70px;
    top: 0px;
    position: fixed;
    left: 0px;
    max-width: 100px;
    z-index: 9991;
    // background-color: var(--app-accent-color);
    padding-top: 100px;
}

.backgroundHider{
    padding-top: 100px;
    width: 100%;
}

.flxCol{
    flex-direction: column;
}

.sidebarInner{
    gap:35px;
    overflow: auto;
    height: calc(100vh - 100px);
}

.sideBarVisible{
    left: 0px;
    transition: var(--app-transition);
}

.sideBarHidden{
    left: -200px;
    transition: var(--app-transition);
}

.menuIconContainer{
    top: 10px !important;
    position: fixed;
    z-index: 9992;
    height: auto;
    background: #ffffff00;
    padding-top: 23px;
    padding: 12px;
    width: 5vw;
    min-width: 70px;
    left: 0px;
    max-width: 100px;
}

.dpNone{
    display: none;
}

.whiteText{
    color: #ffffff;
}

.dsBlock{
    display: block;
}